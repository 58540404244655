.annimatedCircle {
  animation: circle-animation 0.7s linear infinite;
  width: var(--size);
}

@keyframes circle-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
