html,
body {
  --color-theme-accent: env(-opera-accent-color);
  --overlay-color: rgba(15, 29, 41, 0.64);
  background-color: var(--background);
}

html.gx-2024,
html.gx-2024 body {
  background-color: transparent;
}

.theme-dark {
  --background: #101214;
  --browser-popup-background: #171b1f;
  --icon-color: #ced1d2;
  --beta-badge-background: #3e4852;
  --beta-badge-color: #e6eef5;
  --scrollbar-color: rgba(102, 102, 102, 0.33);
  --scrollbar-hover-color: rgba(153, 153, 153, 0.53);

  --accent-color: #45b0e6;

  --primary-font-color: #fafafa;
  --secondary-font-color: #a8abad;

  --primary-button-color: #45b0e6;
  --primary-button-hover-color: #4993b8;
  --primary-button-focus-color: #1b79a8;
  --primary-button-disabled-color: #3e4852;
  --primary-button-font-color: #ffffff;
  --primary-button-border-color-normal: #3e4852;
  --primary-button-border-color-hovered: #3e4852;
  --primary-button-border-color-pressed: #3e4852;
  --circular-progress-outer-circle: rgba(37, 40, 53, 1);
  --default-text-color: rgba(255, 255, 255);
  --feedback-button-background-hover-color: rgba(255, 255, 255, 0.04);
  --feedback-button-text-color: var(--icon-color);
  --textfield-background-hover-color: var(--input-background-color);
  --textfield-placeholder-color: rgb(157, 161, 164);
  --textfield-caret-color: #ffffff;
  --textfield-text-color: #ffffff;
  --textfield-icon-color: #ffffff;
  --textfield-icon-color-disabled: #ffffff33;
  --textfield-separator-color: #ffffff33;

  --selected-pinboard-color: #3e4852;

  --primary-background-color: #101214;
  --secondary-background-color: #171b1f;

  --input-background-color: #2a343d;
  --input-border-color: #3e4852;

  --button-background-color: #2e2c3a;
  --button-background-hover-color: #2e2c3a;
  --button-background-focus-color: #2e2c3a;

  --separator-color: #3e4852;
  --panel-border-color: transparent;
}

.theme-light {
  --background: #eef3f7;
  --browser-popup-background: #ffffff;
  --icon-color: #6d7f90;
  --beta-badge-background: #8997a3;
  --beta-badge-color: white;
  --scrollbar-color: rgba(153, 153, 153, 0.33);
  --scrollbar-hover-color: rgba(102, 102, 102, 0.53);

  --accent-color: #0199ff;

  --primary-font-color: #121314;
  --secondary-font-color: #6a6a75;

  --primary-button-color: #0199ff;
  --primary-button-hover-color: #0084ff;
  --primary-button-focus-color: #0b55e8;
  --primary-button-disabled-color: #d8e2eb;
  --primary-button-font-color: #ffffff;
  --primary-button-border-color-normal: #ced1d2;
  --primary-button-border-color-hovered: #ced1d2;
  --primary-button-border-color-pressed: #bec1c2;
  --circular-progress-outer-circle: rgba(209, 219, 227, 1);
  --default-text-color: rgba(0, 0, 0, 0.87);
  --feedback-button-background-hover-color: rgba(0, 0, 0, 0.04);
  --feedback-button-text-color: #2a343d;
  --textfield-background-hover-color: var(--input-background-color);
  --textfield-placeholder-color: rgba(172, 172, 172);
  --textfield-caret-color: #000000;
  --textfield-text-color: rgb(37, 37, 37);
  --textfield-icon-color: rgba(42, 52, 61, 1);
  --textfield-icon-color-disabled: rgba(218, 226, 234, 1);
  --textfield-separator-color: #dae2ea;

  --selected-pinboard-color: #eff3f6;

  --primary-background-color: #eef3f7;
  --secondary-background-color: #ffffff;

  --input-background-color: #ffffff;
  --input-border-color: #d8e2eb;

  --button-background-color: #ffffff;
  --button-background-hover-color: #ffffff;
  --button-background-focus-color: #ffffff;

  --separator-color: #d8e2eb;
  --panel-border-color: transparent;
}

.popup.theme-dark.color-theme:not(.gx) {
  --browser-popup-background: hsl(
    env(-opera-background-color-h) calc(25 + 25 * env(-opera-color-picker-x))
      calc(16 + 4 * env(-opera-color-picker-x))
  );
  --accent-color: env(-opera-accent-color);
  --accent-color-hovered: hsl(env(-opera-accent-color-h), 90%, 59%);
  --accent-color-pressed: hsl(env(-opera-accent-color-h), 90%, 64%);
  --input-background-color: color-mix(
    in srgb,
    white 30%,
    var(--browser-popup-background)
  );
  --selected-pinboard-color: color-mix(
    in srgb,
    white 20%,
    var(--browser-popup-background)
  );
}

.popup.theme-light.color-theme:not(.gx) {
  --browser-popup-background: hsl(
    env(-opera-background-color-h) calc(100 * env(-opera-color-picker-x))
      calc(100 - 3 * env(-opera-color-picker-x))
  );
  --accent-color: var(--color-theme-accent);
  --accent-color-hovered: hsl(env(-opera-accent-color-h), 90%, 59%);
  --accent-color-pressed: hsl(env(-opera-accent-color-h), 90%, 64%);
  --input-background-color: color-mix(
    in srgb,
    white 60%,
    var(--browser-popup-background)
  );
  --selected-pinboard-color: color-mix(
    in srgb,
    white 50%,
    var(--browser-popup-background)
  );
}

.popup.color-theme:not(.gx) {
  --background: var(--browser-popup-background);
  --primary-background-color: var(--input-background-color);
  --primary-button-color: var(--accent-color);
  --primary-button-hover-color: var(--accent-color-hovered);
  --primary-button-focus-color: var(--accent-color-pressed);
}

.theme-dark.gx,
.theme-light.gx {
  --background: var(--primary-background-color);
}

.theme-dark.gx.gx2024,
.theme-light.gx.gx2024 {
  --background: transparent;
}

.theme-dark.gx ::selection,
.theme-light.gx ::selection {
  background-color: var(--accent-color);
  color: var(--primary-button-font-color);
}

.theme-dark.gx {
  --gx-accent-color: #fa1e4e;
  --gx-highlight-color: #fa1e4e80;

  --gx-primary-font-color: #ffffff;
  --gx-secondary-font-color: #bfb6d2;
  --gx-primary-button-font-color: #ffffff;

  --gx-primary-button-color: #fa1e4e;
  --gx-primary-button-hover-color: #fa1e4e;
  --gx-primary-button-focus-color: #fa1e4e;
  --gx-primary-button-disabled-color: #ededed;

  --gx-secondary-button-color: #1c1726;
  --gx-secondary-button-hover-color: #121019;
  --gx-secondary-button-focus-color: #121019;

  --gx-primary-background-color: #121019;
  --gx-secondary-background-color: #1c1726;

  --gx-input-background-color: #251f33;
  --gx-input-border-color: #4a3e65;

  --gx-button-background-color: #2e273f;
  --gx-button-background-hover-color: #4a3e65;
  --gx-button-background-focus-color: #8c7db0;

  --gx-separator-color: #4a3e65;

  --beta-badge-background: var(--gx-primary-background-color);
  --beta-badge-color: var(--gx-secondary-font-color);

  --panel-border-color: var(--gx-separator-color);
}

.theme-light.gx {
  --gx-accent-color: #fa1e4e;
  --gx-highlight-color: #fa1e4e80;

  --gx-primary-font-color: #000000;
  --gx-secondary-font-color: #372f4c;
  --gx-primary-button-font-color: #ffffff;

  --gx-primary-button-color: #fa1e4e;
  --gx-primary-button-hover-color: #fa1e4e;
  --gx-primary-button-focus-color: #fa1e4e;
  --gx-primary-button-disabled-color: #2e273f80;

  --gx-secondary-button-color: #f9f9fb;
  --gx-secondary-button-hover-color: #e9e6ef;
  --gx-secondary-button-focus-color: #e9e6ef;

  --gx-primary-background-color: #f4f2f7;
  --gx-secondary-background-color: #f9f9fb;

  --gx-input-background-color: #ddd9e8;
  --gx-input-border-color: #c7c0d8;

  --gx-button-background-color: #e3dfec;
  --gx-button-background-hover-color: #c7c0d8;
  --gx-button-background-focus-color: #5c4e7e;

  --gx-separator-color: #c7c0d8;

  --beta-badge-background: var(--gx-input-background-color);
  --beta-badge-color: var(--gx-secondary-font-color);

  --panel-border-color: var(--gx-separator-color);
}

.theme-dark.gx body,
.theme-light.gx body {
  --accent-color: var(--gx-accent-color);

  --primary-font-color: var(--gx-primary-font-color);
  --secondary-font-color: var(--gx-secondary-font-color);

  --primary-button-color: var(--gx-primary-button-color);
  --primary-button-hover-color: var(--gx-primary-button-hover-color);
  --primary-button-focus-color: var(--gx-primary-button-focus-color);
  --primary-button-disabled-color: var(--gx-primary-button-disabled-color);
  --primary-button-font-color: var(--gx-primary-button-font-color);

  --primary-background-color: var(--gx-primary-background-color);
  --secondary-background-color: var(--gx-secondary-background-color);

  --input-background-color: var(--gx-input-background-color);
  --input-border-color: var(--gx-input-border-color);

  --button-background-color: var(--gx-button-background-color);
  --button-background-hover-color: var(--gx-button-background-hover-color);
  --button-background-focus-color: var(--gx-button-background-focus-color);

  --separator-color: var(--gx-separator-color);
}

body {
  --pinboard-navigation-height: 72px;
}

body.isNarrowView {
  --pinboard-navigation-height: 108px;
}

.App {
  height: 100%;
  position: fixed;
  overflow-y: auto;
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: var(--scrollbar-color);
  border: 2px solid transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-hover-color);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.gx .pinboard-item-content::before,
.gx .add-pin-btn > span::before,
.gx #add-pin-new-group-btn > span::before {
  background-color: var(--input-background-color);
  border-radius: inherit;
  clip-path: polygon(
    16px 0,
    100% 0,
    100% calc(100% - 16px),
    calc(100% - 16px) 100%,
    0 100%,
    0 16px
  );
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
}

.gx .item-image:first-child,
.gx .item-video {
  border-radius: 4px;
  clip-path: polygon(
    16px 0,
    calc(100% - 4px) 0,
    100% 4px,
    100% 100%,
    0 100%,
    0 16px
  );
}

.gx #add-pin-new-group-btn:hover,
.gx .add-pin-btn:hover,
.gx .pinboard-item:not(.edited):not(.picking-emoji):hover {
  background-color: transparent;
  filter: drop-shadow(0px 0px 2px var(--gx-highlight-color));
}

.gx .pinboard-item.edited:not(.has-dialog),
.gx .pinboard-item-dialog {
  filter: drop-shadow(0px 0px 4px var(--primary-button-color));
}

.gx .dense-layout .pinboard-group > * {
  margin: 0 3px 3px 0;
}

.gx .MuiCheckbox-colorPrimary:not(.Mui-checked) .MuiSvgIcon-root {
  fill: var(--separator-color);
}

.gx .add-pin-btn > span::before,
.gx #add-pin-new-group-btn > span::before {
  border-radius: 4px;
}

.gx #add-pin-new-group-btn svg,
.gx .add-pin-btn svg {
  z-index: 1;
}

/* Emoji picker overrides */

.gx .emoji-mart {
  font-size: 14px;
  filter: drop-shadow(0px 0px 2px var(--gx-accent-color));
}

.gx .emoji-mart-anchor {
  color: var(--secondary-font-color);
}

.gx .emoji-mart-anchor:hover,
.gx .emoji-mart-anchor:focus,
.gx .emoji-mart-anchor-selected {
  color: var(--primary-font-color);
}

.gx .emoji-mart-category-label span {
  background-color: transparent !important;
}

.gx .emoji-mart-dark .emoji-mart-bar {
  border-color: var(--panel-border-color);
}

.gx .emoji-mart-search input {
  background-color: var(--input-background-color) !important;
  border-color: var(--input-border-color) !important;
  color: var(--primary-font-color) !important;
  font-size: 14px !important;
  margin: 12px 0 8px 0 !important;
}

.gx .emoji-mart-anchor-selected {
  color: var(--gx-accent-color) !important;
}

.gx .emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  background-color: var(--gx-accent-color) !important;
}

.gx .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: var(--gx-secondary-button-hover-color) !important;
  border: 1px solid var(--gx-accent-color) !important;
}

.gx .emoji-mart-no-results {
  color: var(--secondary-font-color);
}
